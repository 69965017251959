import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import socketIOClient from "socket.io-client";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import { imageURL, socketURL } from "../Services/api/baseURL";
import Swal from "sweetalert2";
// import audioURL from "../assets/audio/mixkit-urgent-simple-tone-loop-2976.wav"
const audioURL = require('../assets/audio/_Zomato_Order_Ringtone_(by Fringster.com).mp3')
// setup fake backend

const Routers = () => {
  const login = useState(JSON.parse(localStorage.getItem("login")))[0];
  const [authenticated, setAuthenticated] = useState(false);
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === "compact-wrapper");
  const layout = localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  const audio = new Audio(audioURL);


  useEffect(() => {
    // window.onload = () => {
    //   alert('Allow Notifications from this website')
    // };

    // const audio = new Audio(audioURL);


    const socket = socketIOClient(socketURL,{
      transports: ['websocket'],
    });

    if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Permission granted for this notification after request');
        }
      });
    }

    if(socket){
      socket.on("connect", () => {
        console.log("Connected to socket");
        
        
        
        // let orderDetails = {id: "1234"}
        // audio.play();
        

        // function startAfterDelay() {

        //   if (Notification.permission === 'granted') {
        //     console.log("Permission granted---")
        //     new Notification("New Order Received", {
        //       body: `Order ID: ${orderDetails.id} - Click to view details`,
        //       icon: `${imageURL}logonew.png`
        //     }).onclick = () => {
        //       window.location.href = `/orders`;
        //       // window.open(`/orders`);
        //       // orders/66e803094443280067f9c1e1
        //     };
        //   } else if (Notification.permission !== 'denied') {
        //     console.log('No permission granted for this notification');

        //     Notification.requestPermission().then(permission => {
        //       if (permission === 'granted') {
        //         console.log('Permission granted for this notification after request');
        //         new Notification("New Order Received", {
        //           body: `Order ID: ${orderDetails.id} - Click to view details`,
        //           icon: `${imageURL}logonew.png`
        //         });
        //       }
        //     });
        //   }

        // // const audio = new Audio(audioURL);
       
        //   console.log("This function is executed after 5 minutes!");

        // }
      
        // setTimeout(startAfterDelay, 10000);
      });

      socket.on("new_order_created", (orderData) => {
        console.log("Connected to new_order_created", orderData);

        let orderDetails = orderData;
        audio.loop = true;

        try {
          audio.play();
        } catch (error) {
          console.log("Error playing")
        }

        const stopAudio = setTimeout(() => {
          audio.pause();
          audio.currentTime = 0;
        }, 180000);


        if (orderDetails){
          Swal.fire({
            title: "New Order Recieved!",

            icon: "success",
            confirmButtonText: "okay",
            confirmButtonColor: "#d3178a",
          }).then((result) => {
            if (result.isConfirmed) {
              clearTimeout(stopAudio);
              audio.pause();
              audio.currentTime = 0;
            } 
          });
        }

          if (Notification.permission === 'granted') {
            console.log("Permission granted---")
            new Notification("New Order Received", {
              body: `Order ID: ${orderDetails.order_id} - Click to view details`,
              icon: `${imageURL}logonew.png`
            }).onclick = () => {
              window.open(`/orders`);
              // orders/66e803094443280067f9c1e1
            };
          } else if (Notification.permission !== 'denied') {
            console.log('No permission granted for this notification');

            Notification.requestPermission().then(permission => {
              if (permission === 'granted') {
                console.log('Permission granted for this notification after request');
                new Notification("New Order Received", {
                  body: `Order ID: ${orderDetails.order_id} - Click to view details`,
                  icon: `${imageURL}logonew.png`
                }).onclick = () => {
                  window.open(`/orders`);
                };
              }
            });
          }

          return () => {
            clearTimeout(stopAudio);
            audio.pause();
            audio.currentTime = 0;
          };

      });
      
      socket.on("order_cancelled", (orderData) => {
        console.log("Connected to order_cancelled");
        console.log('Order cancelled', orderData)
        // const audio = new Audio(audioURL);
        // audio.play();
      });

    return () => {
      socket.off('new_order_created')
      socket.off('order_cancelled')
      socket.disconnect();
    };
  }


//   function startAfterDelay() {
//     console.log("This function is executed after 5 minutes!");
//   }

// // Set a timeout for 5 minutes
//   setTimeout(startAfterDelay, 10000);
  }, []);

  // useEffect(() => {
  //   window.onload = () => {
  //     alert('Loading')
  //   };

  //   window.addEventListener('DOMContentLoaded', (event) => {
  //     alert('New order received! Click to enable sound notifications.');
  //   });

  //   Notification.requestPermission().then(permission => {
  //     if (permission === 'granted') {
  //       console.log('Permission granted for this notification after request');
  //       const notification = new Notification("New Order Received", {
  //         body: `Order ID:1 - Click to view details`,
  //         icon: `${imageURL}logonew.png`
  //       });

  //       notification.onshow = () => {
  //         try {
  //           audio.play();
  //         } catch (error) {
  //           alert("Error playing")
  //           // console.warn("Audio playback failed. User interaction is required.");
  //           // console.error("Audio playback failed:", error);
  //         }
  //       };

  //       // Stop the audio if the notification is clicked
  //       notification.onclick = () => {
  //         audio.pause();
  //         audio.currentTime = 0; // Reset the audio
  //         window.focus(); // Bring the admin app to the foreground
  //       };
  //     }
  //   });
  // }, [])

  useEffect(() => {
    let abortController = new AbortController();
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={"/"} element={<PrivateRoute />}>
            {login || authenticated ? (
              <>
                <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`/dashboard`} />} />
                <Route exact path={`/`} element={<Navigate to={`/dashboard`} />} />
              </>
            ) : (
              ""
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>

          <Route exact path={`/login`} element={<Signin />} />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
